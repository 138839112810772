import {
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { SignInButton } from "../Buttons";

type Props = {
  isLoading?: boolean;
};

const LandingNavbar = ({ isLoading = false }: Props) => {
  return (
    <Flex
      h="60px"
      m={"20px 5% 0 5%"}
      justifyContent="space-between"
      align="center"
    >
      <Flex gap={"25px"} align="center">
        <Image src="/MainLogo.svg" alt="Cooking Logo" width={50} height={50} />
        <Text
          textAlign={useBreakpointValue({ base: "center", md: "left" })}
          fontFamily={"heading"}
          color={useColorModeValue("gray.800", "white")}
          fontSize="xl"
          fontWeight="bold"
        >
          Recipe Log
        </Text>
      </Flex>
      <SignInButton
        text={"Sign In"}
        isLoading={isLoading}
        rounded="full"
        px="6"
      />
    </Flex>
  );
};

export default LandingNavbar;
