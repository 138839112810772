import { Flex, Image, Spinner, Text } from "@chakra-ui/react";

const MobileLoadingDisplay = () => {
  return (
    <Flex h="100vh" bgColor={"brand.500"} w="100%" justifyContent={"center"}>
      <Flex
        justifyContent={"space-between"}
        mt="25%"
        mb="25%"
        flexDirection={"column"}
      >
        <Flex flexDir={"column"} gap="10px" alignItems={"center"}>
          <Text
            textAlign={"center"}
            // textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"heading"}
            fontSize="3xl"
            fontWeight="bold"
            color={"white"}
          >
            Recipe Log
          </Text>
          <Image
            // paddingLeft={"5px"}
            verticalAlign={""}
            src="/MainLogo.svg"
            alt="Cooking Logo"
            width={20}
            height={20}
          />
        </Flex>

        <Flex gap="15px">
          <Spinner color={"white"} size="lg" />
          <Text color={"white"}>Loading...</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MobileLoadingDisplay;
