import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";
import { IconType } from "react-icons";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { BsCardChecklist, BsFillCollectionFill } from "react-icons/bs";
import { MdMobileScreenShare, MdOutlineAddChart } from "react-icons/md";
import { RiFolderSharedLine } from "react-icons/ri";
import { SignInButton } from "../components";
import MobileStoreButton from "../components/Buttons/MobileStoreButton";
import { MobileLoadingDisplay } from "../components/MobileLoadingDisplay";
import { LandingNavbar } from "../components/Navbar";
import { iOSUrl } from "../config";
import { firebaseLogScreenView } from "../config/firebase";
import { useAuthentication } from "../hooks";
import useCapacitorAuth from "../hooks/useCapacitorAuth";
import { isNative } from "../utils";

type Props = {
  Icon: IconType;
  headerText: string;
  descriptionText: string;
  bgColor: string;
  iconColor: string;
};

const InformationBubble = ({
  Icon,
  headerText,
  descriptionText,
  bgColor,
  iconColor,
}: Props) => (
  <Flex
    flexDir={"column"}
    justify="start"
    alignItems={"start"}
    gap="5px"
    width={"250px"}
    textAlign="start"
  >
    <Box
      padding={"5px"}
      w="50px"
      h="50px"
      bgColor={bgColor}
      borderRadius="10px"
      marginBottom={"10px"}
      // justifyContent={"center"}
    >
      <Icon
        color={iconColor}
        size="30px"
        style={{ margin: "auto", marginTop: "5px" }}
      />
    </Box>
    <Text fontSize={"18px"} fontWeight={"bold"}>
      {headerText}
    </Text>
    <Text flexWrap={"wrap"}>{descriptionText}</Text>
  </Flex>
);

//TODO: Create a hero component and clean this up.
const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    query: { callbackUrl },
    push,
    isReady,
  } = useRouter();

  const { isAuthenticated, isLoading: isAuthenticationLoading } =
    useAuthentication();

  //If the user is native - Do we want to show a loading indicator???

  useCapacitorAuth();

  firebaseLogScreenView("Landing", "LandingPage");

  useEffect(() => {
    if (isAuthenticated) {
      push("/recipes");
    }
  }, [isAuthenticated]);

  //We want to add a loading check here ONLY if they're native. (to prevent first time users hitting this)

  if (isNative && isAuthenticationLoading) {
    return <MobileLoadingDisplay />;
  }

  return (
    <>
      <LandingNavbar isLoading={isLoading} />
      <Container mt="5px" maxW={"5xl"}>
        <Stack
          textAlign={"center"}
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            <Text as={"span"} color={"brand.500"}>
              Never{" "}
            </Text>
            lose a recipe{" "}
            <Text as={"span"} color={"brand.500"}>
              again.
            </Text>
          </Heading>
          <Text color={"gray.500"} maxW={"3xl"}>
            Keep all your favorite recipes in one place and easily access them
            from anywhere, anytime. Share recipes with your friends and say
            goodbye to messy recipe books and hello to a seamless cooking
            experience.
          </Text>
          <Stack spacing={6} direction={"row"}>
            <SignInButton
              isLoading={isLoading}
              text="Get started"
              rounded="full"
              px="6"
            />
            <MobileStoreButton
              store="ios"
              url={iOSUrl}
              linkProps={{ title: "iOS Store Button" }}
              width="140px"
            />

            {/* <Button rounded={"full"} px={6}>
              Learn more
            </Button> */}
          </Stack>
          {/* <Image
            mt="-5px"
            //   h="250px"
            boxSize={"sm"}
            src="/book_illustration.svg"
            alt="Create a Recipe Collection"
            objectFit={"contain"}
          /> */}
          <Image
            mt="-5px"
            //   h="250px"
            boxSize={"sm"}
            src="/landing_page_gif_v2.gif"
            alt="Create a Recipe Collection"
            objectFit={"contain"}
          />
          <Flex
            paddingBottom={"15px"}
            paddingLeft="15px"
            gap="50px"
            alignItems={"start"}
            flexWrap="wrap"
            marginTop={"100px !important"}
          >
            <InformationBubble
              headerText="Create recipes"
              Icon={AiOutlineFolderAdd}
              descriptionText="Unleash your culinary genius with our intuitive recipe creator. Whip up delicious masterpieces and share them with friends"
              bgColor="#C7D2FE"
              iconColor="#0b39e7"
            />
            <InformationBubble
              headerText="Share your favorite recipes"
              Icon={MdMobileScreenShare}
              descriptionText="Share your mouthwatering recipes. Easily share recipes with friends and family"
              bgColor="#D9F99D"
              iconColor="#1f2e07"
            />
            <InformationBubble
              headerText="Create Recipe Collections"
              Icon={BsFillCollectionFill}
              descriptionText="Organize and personalize your own recipe collections. From comfort food to exotic cuisines, create a virtual cookbook that suits your unique tastes"
              bgColor="#FDE68A"
              iconColor="#dc610f"
            />
            <InformationBubble
              headerText="Share Recipe Collections"
              Icon={RiFolderSharedLine}
              descriptionText="Elevate your recipe collections by adding and sharing new dishes. Collaborate with friends, family, and fellow food lovers to create a treasure trove of amazing dishes"
              bgColor="#FED7AA"
              iconColor="#783000"
            />
            <InformationBubble
              headerText="Shopping Lists"
              Icon={BsCardChecklist}
              descriptionText="Never miss an ingredient again. Effortlessly create shopping lists for your recipes, ensuring you have everything you need to whip up your favorite dishes."
              bgColor="#FBCFE8"
              iconColor="#f00808"
            />
            <InformationBubble
              headerText="Effortless Meal Planning"
              Icon={MdOutlineAddChart}
              descriptionText="Save time and effort with automatic ingredient addition. Add recipes to your shopping list, and watch as the app magically populates it with all the necessary ingredients.            "
              bgColor="#BAE6FD"
              iconColor="#0c35dc"
            />
          </Flex>
        </Stack>
      </Container>
    </>
  );
};

LandingPage.getLayout = function getLayout(page: ReactElement) {
  return <>{page}</>;
};

export default LandingPage;
