import { AnchorHTMLAttributes, CSSProperties, FC, HTMLProps } from "react";

const imageLinks = {
  ios: "/apple-download-button.svg",
  android:
    "https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png",
};

interface MobileStoreButtonProps extends HTMLProps<HTMLDivElement> {
  store: "ios" | "android";
  url: string;
  height?: number | string;
  width?: number | string;
  linkStyles?: CSSProperties;
  linkProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
}

const MobileStoreButton: FC<MobileStoreButtonProps> = ({
  store,
  url,
  height = 75,
  width = 255,
  linkStyles,
  linkProps,
  ...props
}) => {
  const defaultLinkStyles: CSSProperties = {
    background: `url(${imageLinks[store]}) no-repeat`,
    backgroundSize: "contain",
    display: "inline-block",
    overflow: "hidden",
    textDecoration: "none",
    height: "100%",
    width: "100%",
    padding: "5px",
    ...linkStyles,
  };

  return (
    <div style={{ height, width, display: "inline-block" }} {...props}>
      <a
        style={defaultLinkStyles}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        {...linkProps}
      >
        &nbsp;
      </a>
    </div>
  );
};

export default MobileStoreButton;
